import React from "react"
import { Link } from "gatsby"
import classes from "./Navigation.module.scss"
import Logo from "../../assets/images/logo.inline.svg"
import Label from "../../assets/images/rendezvenyek.inline.svg"
import SocialIcon from "../SocialIcon/SocialIcon"

export default ({ clickedLogo, clickedMenu, menuLinks }) => {
  return (
    <nav className={classes.Navigation} role="navigation">
      <div className={classes.NavHeader}>
        <Link to="/" className={classes.Logo} onClick={clickedLogo}>
          <Logo />
        </Link>
        <div className={classes.Label}>
          <Label />
        </div>
      </div>
      <ul className={classes.Menu}>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link
              to={link.link}
              activeClassName={classes.active}
              partiallyActive={true}
              onClick={clickedMenu}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className={classes.NavFooter}>
        <div className={classes.Social}>
          <SocialIcon name="facebook" />
          <SocialIcon name="instagram" />
          <SocialIcon name="pinterest" />
        </div>
        <div className={classes.Copyright}>© Dream Day events</div>
      </div>
    </nav>
  )
}
