const initialState = {
  frontIsActive: null,
  prevFrontIsActive: false,
}
const SET_FRONT_ACTIVE = "SET_FRONT_ACTIVE"
const SET_PREVFRONT_ACTIVE = "SET_PREVFRONT_ACTIVE"
export const setFrontIsActive = frontIsActive => ({
  type: SET_FRONT_ACTIVE,
  frontIsActive,
})
export const setPrevFrontIsActive = prevFrontIsActive => ({
  type: SET_PREVFRONT_ACTIVE,
  prevFrontIsActive,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FRONT_ACTIVE:
      return { ...state, frontIsActive: action.frontIsActive }
    case SET_PREVFRONT_ACTIVE:
      return { ...state, prevFrontIsActive: action.prevFrontIsActive }
    default:
      return state
  }
}
