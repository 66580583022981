import React, { useEffect } from "react"
import ReactGA from "react-ga"
import classes from "./Layout.module.scss"
import Navigation from "../Navigation/Navigation"
import MobileNavigation from "../MobileNavigation/MobileNavigation"
import favicon from "../../assets/images/favicon.ico"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import "../../pages/global.scss"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { useDispatch, useSelector } from "react-redux"
import { setFrontIsActive, setPrevFrontIsActive } from "../../state/app"
import CookieConsent from "react-cookie-consent"

export default ({ children, location }) => {
  const { title, description, trackingId } = useSiteMetadata()
  const { menuLinks } = useSiteMetadata()
  const dispatch = useDispatch()
  const frontIsActive = useSelector(state => state.app.frontIsActive)
  const prevFrontIsActive = useSelector(state => state.app.prevFrontIsActive)
  const frontIsActiveChanged = prevFrontIsActive !== frontIsActive
  const layoutClasses = [
    classes.Wrapper,
    frontIsActive === null || frontIsActive ? classes.frontIsActive : "",
  ]

  useEffect(() => {
    if (frontIsActive === null) {
      dispatch(setFrontIsActive(location.pathname === "/"))
    }
  }, [frontIsActive, dispatch, location.pathname])

  return (
    <main className={layoutClasses.join(" ")}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content={description} />
        <title>{title}</title>
        <link rel="icon" href={favicon}></link>
        <html lang="hu" />
      </Helmet>
      <section className={classes.NavSection}>
        <MobileNavigation
          clickedMenu={() => {
            dispatch(setPrevFrontIsActive(frontIsActive))
            dispatch(setFrontIsActive(false))
          }}
          clickedLogo={() => {
            dispatch(setPrevFrontIsActive(frontIsActive))
            dispatch(setFrontIsActive(true))
          }}
          menuLinks={menuLinks}
        ></MobileNavigation>
        <Navigation
          clickedMenu={() => {
            dispatch(setPrevFrontIsActive(frontIsActive))
            dispatch(setFrontIsActive(false))
          }}
          clickedLogo={() => {
            dispatch(setPrevFrontIsActive(frontIsActive))
            dispatch(setFrontIsActive(true))
          }}
          menuLinks={menuLinks}
          isFront={frontIsActive}
        ></Navigation>
      </section>
      <section className={classes.ContentSection}>
        {frontIsActiveChanged ? (
          <TransitionGroup
            component={null}
            childFactory={child => React.cloneElement(child)}
          >
            <CSSTransition
              in={true}
              timeout={{
                appear: 1000,
                enter: 1000,
                exit: 0,
              }}
              classNames={classes}
              key={location.pathname}
            >
              {children}
            </CSSTransition>
          </TransitionGroup>
        ) : (
          <TransitionGroup
            component={null}
            childFactory={child => React.cloneElement(child)}
          >
            <CSSTransition
              in={true}
              timeout={{
                appear: 0,
                enter: 0,
                exit: 0,
              }}
              classNames={classes}
              key={location.pathname}
            >
              {children}
            </CSSTransition>
          </TransitionGroup>
        )}
      </section>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Elfogadom"
        declineButtonText="Elutasítom"
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={{
          marginLeft: "1rem",
          backgroundColor: "#fc74a6",
          color: "#ffffff",
        }}
        declineButtonStyle={{
          marginLeft: "1rem",
          border: "1px solid #fc74a6",
          backgroundColor: "#ffffff",
          color: "#fc74a6",
        }}
        style={{
          borderTop: "1px solid #a8b0b5",
          background: "#ffffff",
          color: "#596369",
        }}
        onAccept={() => {
          ReactGA.initialize(trackingId)
        }}
      >
        A weboldal sütiket (cookie-kat) használ, hogy biztonságos böngészés
        mellett a legjobb felhasználói élményt nyújtsa.
      </CookieConsent>
    </main>
  )
}
