import React, { useState, useEffect, createRef } from "react"
import { Link } from "gatsby"
import classes from "./MobileNavigation.module.scss"
import { HamburgerCollapse } from "react-animated-burgers"
import Logo from "../../assets/images/logo.inline.svg"
import SocialIcon from "../SocialIcon/SocialIcon"

export default ({ clickedLogo, clickedMenu, menuLinks }) => {
  const [open, setOpen] = useState(false)
  const mobileNavigationRef = createRef()

  useEffect(() => {
    document.addEventListener("click", handleCanvasClick)
    return () => document.removeEventListener("click", handleCanvasClick)
  })

  const handleMenuLinkClick = e => {
    setOpen(false)
    clickedMenu()
  }

  const handleToggleButtonClick = () => {
    setOpen(!open)
  }

  const handleCanvasClick = e => {
    if (
      mobileNavigationRef.current &&
      !mobileNavigationRef.current.contains(e.target)
    ) {
      setOpen(false)
    }
  }

  const menuClasses = [classes.MobileMenu, open ? classes.isOpen : ""]

  return (
    <nav className={classes.MobileNavigation} role="navigation">
      <Link to="/" className={classes.Logo} onClick={clickedLogo}>
        <Logo />
      </Link>
      <HamburgerCollapse
        toggleButton={handleToggleButtonClick}
        className={classes.Toggle}
        buttonWidth={30}
        isActive={open}
        barColor="#fc74a6"
      />
      <ul className={menuClasses.join(" ")} ref={mobileNavigationRef}>
        {menuLinks.map(link => (
          <li key={link.name} data-name={link.name} data-link={link.link}>
            <Link to={link.link} onClick={handleMenuLinkClick}>
              {link.name}
            </Link>
          </li>
        ))}
        <li className={classes.Social}>
          <SocialIcon name="facebook" />
          <SocialIcon name="instagram" />
          <SocialIcon name="pinterest" />
        </li>
      </ul>
    </nav>
  )
}
