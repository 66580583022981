// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-egy-kis-extra-index-js": () => import("./../src/pages/egy-kis-extra/index.js" /* webpackChunkName: "component---src-pages-egy-kis-extra-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-index-js": () => import("./../src/pages/kapcsolat/index.js" /* webpackChunkName: "component---src-pages-kapcsolat-index-js" */),
  "component---src-pages-szolgaltatasok-index-js": () => import("./../src/pages/szolgaltatasok/index.js" /* webpackChunkName: "component---src-pages-szolgaltatasok-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-service-page-js": () => import("./../src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

