import React from "react"
import classes from "./SocialIcon.module.scss"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

export default ({ name }) => {
  const { socialLinks } = useSiteMetadata()

  return (
    <a
      href={socialLinks[name]}
      className={classes.SocialIcon}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={`icon-${name}`}></span>
    </a>
  )
}
